 <div class="entity-profile-main">
   <span class="back-option">
    {{ "cc.sidemenu.entity-profile" | translate }}
  </span>
  <div *ngIf="mcEntity" class="cardbox mt-medium">
    <div class="cardbox_header">

      <span class="cardbox_title">{{ mcEntity.name | uppercase }}</span>

      <div class="entity_profile_actions">
        <mc-edit-entity-basic-info-modal
          [editEntityId]="entityId" [parentData]="mcEntity"
          (entityBasicInfoEvent)="onSuccessEditBasicInfo($event)"></mc-edit-entity-basic-info-modal>
        <div>
          <span class="entity-basic-info-title" [routerLink]="['/entity/'+entityId+'/pricelists/services-and-pricelist']"><i class="fas fa-tags mc-cursor-pointer"></i> {{'cc.pricelist.services-and-pricelist' | translate}}</span>
        </div>
        <button mat-button
                class="primary-btn"
                type="submit"
                *ngIf="isStatusPending(mcEntity.statusCd)"
                (click)="openProfileActivationDialog()">
            {{ 'cc.entity.profile.activation' | translate }}
        </button>
      </div>

    </div>
    <div class="divider"></div>
    <div class='dialog-row-wrapper dialog-header'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'pp.invoice.status' | translate}}:</span>
        <span class='dialog-col-value'>
          <span [innerHTML]="iconStatus | safeHtml"></span>{{ (mcEntity.statusCd ? getFormattedStatus(mcEntity.statusCd) : '-') }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.abbreviation' | translate}}:</span>
        <span class='dialog-col-value'>{{ mcEntity.abbreviation ? mcEntity.abbreviation : '-' }}</span>
      </div>
      <div class='dialog-col' >
        <span class='dialog-col-label'>{{ 'cc.users.edit.country' | translate}}:</span>
        <span class='dialog-col-value'>{{ mcEntity.countryName ? mcEntity.countryName : '-' }}</span>
      </div>
      <div class='dialog-col' >
        <span class='dialog-col-label'>{{ 'cc.common.edit.email' | translate}}:</span>
        <span class='dialog-col-value'>{{ mcEntity.email ? mcEntity.email : '-' }}</span>
      </div>
    </div>
  </div>
  <div class="card entity-profile-tabs mt-small">
    <ul class="d-flex">
      <li>
        <a [routerLink]="'info'" routerLinkActive="active">{{'cc.common.profile' | translate}}</a>
      </li>
      <li>
        <a [routerLink]="'bank-accounts'" routerLinkActive="active">{{'cc.common.bank-accounts' | translate}}</a>
      </li>
      <li>
        <a [routerLink]="'attributes'" [queryParams]="{ id: entityId }" routerLinkActive="active">{{'cc.common.view.attributes' | translate}}</a>
      </li>
      <li>
        <a [routerLink]="'settings'" routerLinkActive="active">{{'cc.sidemenu.settings' | translate}}</a>
      </li>
      <li>
        <a [routerLink]="'invitations'" routerLinkActive="active">{{'cc.entity.profile.invitations' | translate}}</a>
      </li>
    </ul>
  </div>

  <div>
    <router-outlet></router-outlet>
  </div>
 </div>

